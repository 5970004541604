 // import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import 'foundation-sites'

// import global library dependencies

let selectors = [
    {class: 'home', path: './modules/home'},
]


//*** IMPORT JS MODULES ****//
import './init-foundation'
import './wp-foundation'
//import './age-gate'
import './modules/sticky-header'
//import './modules/video-inline'
import './modules/store-locator'
import './modules/parallax'
import './modules/slider'
import './modules/headerScrollAnimations'
import './modules/menu'