import ScrollMagic from 'scrollmagic'
import { TweenMax, Linear } from 'gsap'
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

    // var controller = new scrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}})
    // var scene = new scrollMagic.Scene({
    //     triggerElement: '.hero-image'
    // })
    // .setTween('.hero-image', {y: "20%", ease: Linear.easeInOut})
    // .addTo(controller)


    // Hero Image
    var controller = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}})
    var scene = new ScrollMagic.Scene({
        triggerElement: '.hero-image',
        offset: 850,
    })
    .setTween(TweenMax.staggerFromTo('#hero-image', 1, {css: {filter: 'grayscale(0)', marginTop: '0'}}, {css: {filter: 'grayscale(1)', marginTop: '-10%'}}))
    .addTo(controller)

    var controller2 = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "70%"}})
    var scene = new ScrollMagic.Scene({
        triggerElement: '#meet-james',
        offset: 90,
    })
    .setTween(TweenMax.staggerFromTo('.bio-bg', 1, {css: {opacity: '0', marginTop: '0'}}, {css: {opacity: '1', marginTop: '-15%'}, ease: Linear.easeInOut}))
    .addTo(controller2)


    // Divider Animations
    var dividerController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "60%"}})
    var scene = new ScrollMagic.Scene({
        triggerElement: '.hero-letter',
        offset: 500,
    })
    .setTween(TweenMax.staggerFromTo('.divider1', 1, {css: {width: '0', height: '3px'}}, {css: {width: '367px', height: '3px'}, ease: Linear.easeInOut}))
    .addTo(dividerController)

    var dividerController2 = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "60%"}})
    var scene = new ScrollMagic.Scene({
        triggerElement: '.bio',
        offset: 700,
    })
    .setTween(TweenMax.staggerFromTo('.divider2', 1, {css: {width: '0', height: '3px'}}, {css: {width: '367px', height: '3px'}, ease: Linear.easeInOut}))
    .addTo(dividerController2)


    // Home Bottle Animations
    var bottleController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "100%"}})
    var scene = new ScrollMagic.Scene({
        triggerElement: '.bottles',
        offset: 70,
    })
    .setTween(TweenMax.staggerFromTo('.bottle img', 1, {css: {opacity: '0'}}, {css: {opacity: '1'}}))
    .addTo(bottleController)


    if ($('body').hasClass('.varietal-container')) {
        // varietal Bottle Animations
        var bottleVarietalController = new ScrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "200%"}})
        var scene = new ScrollMagic.Scene({
            triggerElement: '.varietal-container',
            offset: 500,
        })
        .setTween(TweenMax.staggerFromTo('.varietal-bottle', 1, {css: {marginTop: '0'}}, {css: {marginTop: '-10%'}}))
        .addTo(bottleVarietalController)
    }