
// $(document).ready(() => {
//     $('#menu-item-{NUMBER}').on('click', (e) => {
//         e.preventDefault()
//         $('#example-animated-menu').css('display', 'none')
//         scrollToElement('#page-element')
//     })

//     $('#arrow-down').on('click', (e) => {
//         e.preventDefault()
//         scrollToElement('#page-element')
//     })
// })


// function scrollToElement( target ) {
//     var topoffset = 30
//     var speed = 100
//     var destination = $( target ).offset().top - topoffset
//     $('html:not(:animated),body:not(:animated)').animate( { scrollTop: destination}, speed, function() {
//         window.location.hash = target
//     })
//     return false
// }
jQuery(function($) {
    $(document).ready(() => {
        $('.menu-item a').on('click', (e) => {
            $('.top-bar').css('display', 'none');
        })
        $('.mbj-close').on('click', function() {
            $('.top-bar').fadeOut(200);
        });
    });
});