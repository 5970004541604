// import scrollMagic from 'scrollmagic'

// // Init ScrollMagic
// var controller = new scrollMagic.Controller();
 
// // Get all triggers - headers of all 3 slides
// let headers = [".slide01 header", ".slide02 header", ".slide03 header", ".slide04 header", ],
//     index = 0;
 
// // SCENE 1
// // create scenes for each of the headers
// headers.forEach(function (header, index) {
         
//     // number for highlighting scenes
//     index += 1;
 
//     // make scene
//     var headerScene = new scrollMagic.Scene({
//         triggerElement: header,
//         offset: -120
//     })
//     .setClassToggle('.slide0' + index, 'is-active')
//     .addTo(controller);
// });

// // SCENE 2
// // change color of the nav for the white slide
// var navScene = new scrollMagic.Scene({
//     triggerElement: '.slide.is-light'
// })
// .setClassToggle('nav', 'is-dark')
// .addTo(controller);
