import Swiper from 'swiper';

$(document).ready(function () {
    //initialize swiper when document ready
    new Swiper ('.swiper-container', {
      // Optional parameters
      slidesPerView: 3,
      spaceBetween: 20,
      slidesPerGroup: 3,
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
          slidesPerGroup: 1,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
          slidesPerGroup: 2,
        }
      }
    })
  });