// $(window).scroll(function() {    // this will work when your window scrolled.
//     var height = $(window).scrollTop();  //getting the scrolling height of window
//     if(height  > 100) {
//         $('header').css({
//             'position': 'fixed',
//             'animation': 'slideDown 1s'
//         });
        
//     } else{
//         $('.header').css({
//             "position": "relative",
//             'animation': 'unset'
//         });
//     }
// });



$(function(){
    $(window).scroll(function(){
      var winTop = $(window).scrollTop();
      if (winTop >= 30) {
        $(".nav-placeholder").height($(".title-bar").outerHeight());
        $("body").addClass("sticky-header");
      } else {
        $(".nav-placeholder").height(0);
        $("body").removeClass("sticky-header");
      }
    });
  });